import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PagePrivacyPolicyQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import PrivacyPolicy from '@webPages/Misc/PrivacyPolicy';

const PrivacyPolicyPage: React.FC<PageProps<PagePrivacyPolicyQuery>> = ({
  data,
}) => {
  const title = data?.privacyPolicy?.frontmatter?.title || '';
  const seoTitle =
    data?.privacyPolicy?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.privacyPolicy?.frontmatter?.seoDescription ||
    data?.privacyPolicy?.excerpt ||
    undefined;
  const body = data?.privacyPolicy?.html || '';

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <PrivacyPolicy title={title} body={body} />
    </Base>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PagePrivacyPolicy {
    privacyPolicy: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "privacy-policy" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
      }
    }
  }
`;
